import { getFile } from "../core/stringBoard/_getFile";
import { getRank } from "../core/stringBoard/_getRank";
import { FILES, RANKS } from "../core/_constants";
/**
 * Возвращает индексы позии из FILES и RANKS
 * @param {number} position
 * @returns
 */
export function getFileAndRankIndexesFromPosition(position) {
    return {
        file: FILES.indexOf(getFile(position)),
        rank: 7 - RANKS.indexOf(getRank(position)),
    };
}
export function createLanFromPositions({ from, to, promotion = "", }) {
    return getFile(from) + getRank(from) + getFile(to) + getRank(to) + promotion.toLowerCase();
}
/**
 * Переводит позицию курсора на доске в IChessPosition. Возвращает null
 * если курсор находится вне доски
 * @param {IChessgunStore} store
 * @param {IXYPosition} mousePos
 * @returns {IChessPosition | null} position
 */
export const getChessPositionByMousePosition = ({ mousePos, chessboardSize, }) => {
    const squareSize = chessboardSize / 8;
    if (mousePos.x < 0 ||
        mousePos.x > chessboardSize ||
        mousePos.y < 0 ||
        mousePos.y > chessboardSize)
        return null;
    let fileIndex = 0;
    let rankIndex = 0;
    while (mousePos.x > (fileIndex + 1) * squareSize) {
        fileIndex++;
    }
    while (mousePos.y > (rankIndex + 1) * squareSize) {
        rankIndex++;
    }
    return {
        file: FILES[fileIndex],
        rank: RANKS[7 - rankIndex],
    };
};
export function getMousePositionFromEvent({ event, rotation, element, }) {
    let clientX, clientY;
    const { left = 0, top = 0, width = 0, height = 0 } = element.getBoundingClientRect() || {};
    if ("clientX" in event) {
        clientX = event.clientX;
        clientY = event.clientY;
    }
    else {
        clientX = event.changedTouches[0].clientX;
        clientY = event.changedTouches[0].clientY;
    }
    const x = clientX - left;
    const y = clientY - top;
    if (rotation === 180) {
        return {
            x: width - x,
            y: height - y,
        };
    }
    const res = { x, y };
    return res;
}
/**
 * Добавляет смещение позиций начала и конца стрелки
 * @param {IXYPosition} pos - позиция, для которой необходимо расчитать смещение
 * @param {IXYPosition} startPos - стартовая позиция, из которой будет начинаться стрелка
 * @param {number} coef - коэффициент смещения
 * @param {number} chessboardSize - размер доски
 *
 * @returns
 */
export function getArrowPointPosition({ pos, startPos, coef = 0.4, chessboardSize, }) {
    const squareSize = chessboardSize / 8;
    const newPos = {
        x: Math.floor(pos.x / squareSize) * squareSize + squareSize / 2,
        y: Math.floor(pos.y / squareSize) * squareSize + squareSize / 2,
    };
    if (!startPos)
        return newPos;
    let shiftX = 0;
    let shiftY = 0;
    if (startPos.x !== newPos.x) {
        shiftX = startPos.x > newPos.x ? coef * squareSize : -coef * squareSize;
    }
    if (startPos.y !== newPos.y) {
        shiftY = startPos.y > newPos.y ? coef * squareSize : -coef * squareSize;
    }
    return {
        x: newPos.x + shiftX,
        y: newPos.y + shiftY,
    };
}
/**
 * Возвращает ширину основания стрелки в зависимости от типа
 * @param {"analysis" | "last move" | "arma"} type - тип стрелки
 * @param {number} chessboardSize - размер доски
 *
 * @returns
 */
export function getArrowStrokeWidth({ type, chessboardSize, }) {
    if (type === "arma") {
        return "8";
    }
    if (type === "analysis") {
        return Math.ceil(chessboardSize / 100).toString();
    }
    return Math.ceil(chessboardSize / 500).toString();
}
