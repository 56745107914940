// ================== WEIGHTS ================== //
export const dragging_figure_weight = 16;
export const selected_figure_weight = 16;
export const possible_move_weight = 11;
export const figure_weight = 8;
export const user_drawing_square_weight = 7;
export const user_drawing_arrow_weight = 10;
export const titles_weight = 5;
export const hover_pos_square_weight = 4;
export const selected_square_weight = 3;
export const premove_square_weight = 2;
export const under_attack_square_weight = 1;
export const last_move_weight = 0;
export const highlight_weight = 0;
export const arrow_weight = 3;
export const square_weight = 0;
export const premove_weight = 0;
export const lines_weight = 0;
// ================== COLORS ================== //
export const selected_square_color = "#ff9e58";
export const premove_square_color = "#ff9e58";
export const under_attack_square_color = "#fc594f";
export const last_move_color = "#9bcfff";
export const ANALYSIS_ARROW_COLOR = "#00F0FF";
export const ARMA_ARROW_COLOR = "#FF2000";
